import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export const columnsHeadings = [
  { title: "Transaction ID", id: "transaction_id" },
  { title: "Name", id: "full_name" },
  { title: "Plan Type", id: "plan_" },
  {
    title: "Email",
    id: "email",
  },
  { title: "Amount", id: "transaction_amount" },
  { title: "Level", id: "level" },
  { title: "Coins", id: "coins" },
  {
    title: "Transaction Type",
    id: "transaction_type",
  },
  { title: "Payment Date", id: "date" },
  { title: "Status", id: "status" },
  {
    title: "Actions",
    id: "actions",
  },
];

export const actions = [
  {
    id: "view",
    text: "View",
    icon: <RemoveRedEyeIcon />,
  },
  {
    id: "approve",
    text: "Approve",
    icon: <CheckBoxOutlinedIcon />,
  },
  {
    id: "reject",
    text: "Reject",
    icon: <CloseOutlinedIcon />,
  },
  {
    id: "viewReason",
    text: "View Only",
    icon: <CloseOutlinedIcon />,
  },
];

export const transactionTypes = [
  {
    id: 1,
    name: "Online",
  },
  {
    id: 2,
    name: "Manual",
  },
];

export const TRANSACTION_STATUSES = {
  pending: {
    text: "Pending",
    value: 0,
  },
  approved: {
    text: "Approved",
    value: 1,
  },
  rejected: {
    text: "Rejected",
    value: 2,
  },
  failed: {
    text: "Failed",
    value: 3,
  },
  dropped: {
    text: "Dropped",
    value: 4,
  },
};

export const SUBSCRIPTION_STATUS = {
  1: "Active",
  2: "Expired",
};

export const bankDetails = [
  { id: "Account_Title", value: "Account Title" },
  { id: "Account_No", value: "Account No" },
  { id: "Branch", value: "Branch" },
  { id: "IBAN", value: "IBAN" },
];

export const bankCredentials = [
  {
    Account_Title: "Ehya Education Services",
    Account_No: 305284572,
    Branch: 1307,
    IBAN: "PK62UNIL0109000305284572",
  },
];

export const headings = {
  mainHeading: "Subscription Requests Log",
  subHeading: "Manage all subscriptions from here",
};
export const skeletonInfo = {
  rows: 8,
  cellCount: 10,
  actions: 3,
  showPerson: false,
};

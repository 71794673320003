import { ReactComponent as Logo } from "./images/Logo.svg";
import AvatarPlaceholder from "./images/Avatar.svg";
import Sample from "./images/sample.jpg";
import BookSample1 from "./images/BookSample.jpeg";
import BookSample2 from "./images/BookSample.png";
import BookSample3 from "./images/bookSample1.jpeg";
import UserPlaceholderImage from "./images/userPlaceholder.png";
import SchoolSample from "./images/schoolSample.jpeg";
import User_Upload from "./images/user_upload.png";
import NotificationSample from "./images/notification-sample1.png";

import { ReactComponent as LightningIcon } from "./Icons/lighnting-icon.svg";
import { ReactComponent as DropDownIcon } from "./Icons/dropdownIcon.svg";
import { ReactComponent as UserGroup } from "./Icons/usersIcon.svg";
import { ReactComponent as NoDataAlternateSvg } from "./Icons/noDataAlternate.svg";
import { ReactComponent as CrossIcon } from "./Icons/CrossIcon.svg";
import { ReactComponent as PrevIcon } from "./Icons/arrow-left.svg";
import { ReactComponent as NextIcon } from "./Icons/arrow-right.svg";
import { ReactComponent as SearchIcon } from "./Icons/search.svg";
import { ReactComponent as FilterIcon } from "./Icons/filter.svg";
import { ReactComponent as BookIcon } from "./Icons/book.svg";
import { ReactComponent as EmailIcon } from "./Icons/email.svg";
import { ReactComponent as GlobeIcon } from "./Icons/globe.svg";
import { ReactComponent as HomeIcon } from "./Icons/home.svg";
import { ReactComponent as LocationIcon } from "./Icons/location.svg";
import { ReactComponent as PhoneIcon } from "./Icons/phone.svg";
import { ReactComponent as FilledCheckedIcon } from "./Icons/filled-check-circle.svg";
import { ReactComponent as CheckedIcon } from "./Icons/check-circle.svg";
import { ReactComponent as CheckedIcon2 } from "./Icons/purple-check-circle.svg";
import { ReactComponent as SendIcon } from "./Icons/sendIcon.svg";
import { ReactComponent as AttachmentIcon } from "./Icons/attachment.svg";
import { ReactComponent as PDFIcon } from "./Icons/pdfIcon.svg";
import { ReactComponent as JPGIcon } from "./Icons/jpgIcon.svg";
import { ReactComponent as PNGIcon } from "./Icons/pngIcon.svg";
import { ReactComponent as ReplyIcon } from "./Icons/replyIcon.svg";
import { ReactComponent as SendingIcon } from "./Icons/send-icon.svg";
import { ReactComponent as NoUsersData } from "./Icons/users_table_default.svg";
import { ReactComponent as UploadIcon } from "./Icons/uploadIcon.svg";
import { ReactComponent as NoDataIcon } from "./Icons/NoData.svg";
import { ReactComponent as ZoomIn } from "./Icons/zoom-in.svg";
import { ReactComponent as ZoomOut } from "./Icons/zoom-out.svg";
import { ReactComponent as RejectionIcon } from "./Icons/rejectionIcon.svg";
import { ReactComponent as MinimizeIcon } from "./Icons/minimize.svg";
import { ReactComponent as MaximizeIcon } from "./Icons/maximize.svg";
import { ReactComponent as ChevUpIcon } from "./Icons/chev-up.svg";
import { ReactComponent as BookCloseIcon } from "./Icons/bookCloseIcon.svg";
import { ReactComponent as UploadImgIcon } from "./Icons/upload-cloud.svg";
import { ReactComponent as DownloadIcon } from "./Icons/downloadIcon.svg";
import { ReactComponent as NewIcon } from "./Icons/new_icon.svg";
import { ReactComponent as TermsAndCondition } from "./Icons/termsAndConditions.svg";
import { ReactComponent as PrivacyPolicy } from "./Icons/privacyPolicy.svg";
import { ReactComponent as Teacher } from "./Icons/Teacher.svg";
import { ReactComponent as Student } from "./Icons/student.svg";
import { ReactComponent as Check } from "./Icons/tick.svg";
import { ReactComponent as SchoolDeactivate } from "./Icons/user-block.svg";
import { ReactComponent as SchoolActivate } from "./Icons/user-check.svg";
import { ReactComponent as UploadFileIcon } from "./Icons/upload-file-icon.svg";
import { ReactComponent as NotificationIcon } from "./Icons/notification.svg";
import { ReactComponent as CheckboxIcon } from "./Icons/checkbox-tick.svg";
import { ReactComponent as RoleIconKid } from "./Icons/RoleIconKid.svg";
import { ReactComponent as RoleIconFamilyKid } from "./Icons/RoleIconFamilyKid.svg";
import { ReactComponent as RoleIconStudents } from "./Icons/RoleIconStudent.svg";
import { ReactComponent as EyeIcon } from "./Icons/eye-icon.svg";
import { ReactComponent as CircleCrossIcon } from "./Icons/Circle-Cross-Icon.svg";
import { ReactComponent as RoleIconTeacher } from "./Icons/RoleIconTeacher.svg";
import { ReactComponent as RoleIconParents } from "./Icons/RoleIconParent.svg";
import { ReactComponent as EyeIcon2 } from "./Icons/eye-icon-2.svg";
import { ReactComponent as DeleteIcon } from "./Icons/delete-icon.svg";
import { ReactComponent as CreateActionIcon } from "./Icons/create-action-ion.svg";
import { ReactComponent as InAppIcon } from "./Icons/tag.svg";
import { ReactComponent as CrossTickIcon } from "./Icons/circle-tick-icon.svg";


import AnimationJSON from "./animation/loading.json";

const Images = {
  Logo,
  AvatarPlaceholder,
  Sample,
  BookSample1,
  BookSample2,
  BookSample3,
  UserPlaceholderImage,
  SchoolSample,
  User_Upload,
  NotificationSample,
};

const Icons = {
  LightningIcon,
  DropDownIcon,
  CrossIcon,
  PrevIcon,
  NextIcon,
  SearchIcon,
  FilterIcon,
  BookIcon,
  EmailIcon,
  GlobeIcon,
  HomeIcon,
  LocationIcon,
  PhoneIcon,
  FilledCheckedIcon,
  CheckedIcon,
  CheckedIcon2,
  SendIcon,
  AttachmentIcon,
  PDFIcon,
  JPGIcon,
  PNGIcon,
  ReplyIcon,
  SendingIcon,
  NoUsersData,
  UploadIcon,
  NoDataIcon,
  ZoomIn,
  ZoomOut,
  RejectionIcon,
  MinimizeIcon,
  MaximizeIcon,
  ChevUpIcon,
  BookCloseIcon,
  UploadImgIcon,
  DownloadIcon,
  NoDataAlternateSvg,
  NewIcon,
  TermsAndCondition,
  PrivacyPolicy,
  UserGroup,
  Teacher,
  Student,
  Check,
  SchoolActivate,
  SchoolDeactivate,
  UploadFileIcon,
  NotificationIcon,
  CheckboxIcon,
  RoleIconKid,
  RoleIconFamilyKid,
  RoleIconStudents,
  RoleIconTeacher,
  RoleIconParents,
  EyeIcon,
  CircleCrossIcon,
  EyeIcon2,
  DeleteIcon,
  CreateActionIcon,
  InAppIcon,
  CrossTickIcon
};

const Animations = {
  AnimationJSON,
};

export { Images, Icons, Animations };

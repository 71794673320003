import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import DetailsCard from "shared/components/DetailsCard";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { searchHandlerHelper } from "shared/utils/helpers";
import { getProductManagerSubscriptionsList } from "shared/services/productManagerService";
import { getProjectHeadSubscriptionsList } from "shared/services/projectHeadService";
import {
  TRANSACTION_STATUSES,
  bankCredentials,
  bankDetails,
} from "pages/subscriptionRequest/constants";
import { routeConstant } from "shared/routes/routesConstant";
import {
  columnHeadings,
  actions,
  headings,
  transactionTypes,
  skeletonInfo,
} from "./constants";
import useDebounce from "shared/customHooks/useDebounce";
import ViewTransactionListModal from "shared/modals/viewTransactionListModal";
import { toastMessage } from "shared/components/toast";
import { useSelector } from "react-redux";
import { getRoutes } from "shared/components/sidebar/constant";
import styles from "./style.module.scss";
import { Icons } from "assets";
import TableSkeleton from "shared/components/TableSkeleton";
import ItemsPerPage from "shared/components/itemsPerPage";
import NoDataFound from "shared/components/noDataFound";
import useWindowSize from "shared/customHooks/windowSize";
import Pagination from "shared/components/pagination";
import {
  plan_level_enums,
  plans_types_enums,
} from "shared/modals/managePlans/constant";
const Subscriptions = () => {
  const { user } = useSelector((state: any) => state.root);
  const screenWidth = useWindowSize();
  const [loader, setLoader] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [approvedSubscription, setApprovedSubscription] = useState<any>([]);
  const [viewUserTransactionList, setViewUserTransactionList] =
    useState<boolean>(false);

  const [subscriptionId, setSubscriptionId] = useState<number | null>(null);
  const routes = getRoutes();
  const activeRole = routes.find(
    (role: any) => role.role_id === Number(user?.user?.role)
  );

  const roles = {
    projectHead: 1,
    productManager: 4,
  };

  const handleView = (item: any) => {
    setSubscriptionId(item?.id);
    setViewUserTransactionList(true);
  };

  const handlers = [{ id: "view", function: handleView }];

  useBreadcrumbs({
    title: "Subscriptions History",
    path: routeConstant.subscriptions.path,
  });

  const getSubscriptionList = () => {
    setLoader(true);
    const service =
      activeRole.role_id === roles.productManager
        ? getProductManagerSubscriptionsList
        : getProjectHeadSubscriptionsList;

    service(null, search, 1, activePage, itemsPerPage)
      .then(({ data, status }) => {
        if (status) {
          setApprovedSubscription(data?.data?.data);
          setLoader(false);
          setTotalPage(data?.data?.last_page);
          setTotalItems(data?.data?.total);
          setCurrentPage(data?.data?.current_page);
          setItemsPerPage(data?.data?.per_page);
        }
      })
      .catch((err: any) => {
        console.log("Error---", err);
        setLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useEffect(() => {
    getSubscriptionList();
  }, [searchText, itemsPerPage, activePage]);

  return (
    <Box>
      {/* Bank Details Card */}
      <Box>
        <DetailsCard
          account={{ title: "Bank Name", value: "United Bank Limited" }}
          info={bankCredentials}
          loader={loader}
          details={bankDetails}
        ></DetailsCard>
      </Box>
      <Box>
        {/* All Approved Subscriptions  Table */}
        <Box className={styles.topLevelContainer}>
          <Box className={styles.topHeadContainer}>
            <Box>
              <div className={styles.tableHeading}>{headings.mainHeading}</div>
              <div className={styles.tableSubHeading}>
                {headings.subHeading}
              </div>
            </Box>
            <Box className={styles.filterContainer}>
              <div className={styles.gridContainer}>
                <Icons.SearchIcon className={styles.searchIcon} />
                <input
                  placeholder="Search"
                  className={styles.input}
                  value={search}
                  onChange={(e) =>
                    searchHandlerHelper(e, setSearch, setActivePage)
                  }
                />
              </div>
            </Box>
          </Box>
          <TableContainer
            className={styles.container}
            component={Paper}
            sx={
              screenWidth > 990
                ? {
                    width: `${screenWidth - 300}px`,
                  }
                : {
                    boxShadow: "none",
                  }
            }
          >
            <Table className={styles.table} aria-label="simple table">
              <TableHead>
                <TableRow className={styles.tableHead}>
                  {columnHeadings?.map((heading, index) => {
                    return <TableCell key={index}>{heading.title}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {loader ? (
                  <TableSkeleton
                    rows={skeletonInfo.rows}
                    cellCount={skeletonInfo.cellCount}
                    actionCount={skeletonInfo.actions}
                    showPersonData={false}
                  ></TableSkeleton>
                ) : (
                  approvedSubscription?.map((item: any, index: number) => {
                    const {
                      user,
                      metadata,
                      price,
                      coins,
                      start_date,
                      type: transType,
                      status,
                      plan,
                    } = item;

                    const planName = metadata?.name;
                    const transactionType = transactionTypes?.find((type) => {
                      return type.id === transType;
                    });
                    const transaction_status =
                      status === TRANSACTION_STATUSES.pending.value
                        ? "Pending"
                        : status === TRANSACTION_STATUSES.approved.value
                        ? "Approved"
                        : status === TRANSACTION_STATUSES.rejected.value
                        ? "Rejected"
                        : status === TRANSACTION_STATUSES.failed.value
                        ? "Failed"
                        : null;
                    const userFullName = user?.parent
                      ? user?.parent?.full_name
                      : user?.reader?.full_name;

                    return (
                      <>
                        <TableRow key={index} className={styles.tableRow}>
                          <TableCell>
                            {userFullName ? userFullName : "Not Provided"}
                          </TableCell>
                          <TableCell>
                            {planName ? planName : "Not Provided"}
                          </TableCell>
                          <TableCell>
                            {user?.email ? user?.email : "Not Provided"}
                          </TableCell>
                          <TableCell>
                            {plan?.level ===
                              plan_level_enums.in_app_international ||
                            plan?.level === plan_level_enums.international
                              ? "$"
                              : "Rs."}
                            {price ? price : "Not Provided"}{" "}
                          </TableCell>
                          <TableCell>
                            {plan?.level ===
                            plan_level_enums.in_app_international
                              ? "In App International"
                              : plan?.level === plan_level_enums.inapp
                              ? "In App"
                              : plan?.level === plan_level_enums.genera
                              ? "General"
                              : plan?.level === plan_level_enums.international
                              ? "International"
                              : "Not Provided"}
                          </TableCell>
                          <TableCell>
                            {coins ? coins : "Not Provided"}
                          </TableCell>
                          <TableCell>
                            {transactionType
                              ? transactionType?.name
                              : "Not Provided"}
                          </TableCell>
                          <TableCell>
                            {start_date
                              ? moment(start_date).format("DD MMM YYYY")
                              : "Not Provided"}
                          </TableCell>
                          <TableCell className={styles.statusCell}>
                            <label className={styles.approved}>
                              {transaction_status
                                ? transaction_status
                                : "Not Provided"}
                            </label>
                          </TableCell>
                          <TableCell>
                            <div className={styles.actionContainer}>
                              <Tooltip
                                componentsProps={{
                                  tooltip: { className: "tooltip" },
                                  arrow: { className: "arrow" },
                                }}
                                placement="top"
                                arrow
                                title="View Receipt"
                              >
                                <div onClick={() => handleView(item)}>
                                  <Icons.EyeIcon2 />
                                </div>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            {totalItems &&
            activePage &&
            totalPage &&
            totalPage > 1 &&
            approvedSubscription.length ? (
              <Pagination
                totalPages={totalPage}
                activePage={activePage}
                setActivePage={setActivePage}
              />
            ) : null}
          </Box>
          <Box>
            {totalItems &&
            activePage &&
            currentPage &&
            approvedSubscription.length ? (
              <ItemsPerPage
                setActivePage={setActivePage}
                currentPage={currentPage}
                setItemsPerPage={setItemsPerPage}
                itemsPerPage={itemsPerPage ? itemsPerPage : 10}
                totalItems={totalItems}
              />
            ) : null}
          </Box>
          <Box>
            {!loader && !(approvedSubscription?.length > 0) && <NoDataFound />}
          </Box>
        </Box>
      </Box>
      {/* View Transaction Details Modal */}
      <Box>
        <ViewTransactionListModal
          viewModal={viewUserTransactionList}
          handleViewClose={() => setViewUserTransactionList(false)}
          userId={subscriptionId}
        />
      </Box>
    </Box>
  );
};

export default Subscriptions;

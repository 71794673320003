import * as yup from "yup";

const loginSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

const createTicketSchema = yup.object({
  title: yup.string().required("Title is required"),
  message: yup.string().required("Message is required"),
  selectedUser: yup.object().nullable().required("Select a user"),
});

const createBookReviewerSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  contact: yup
    .string()
    .required("Contact is required")
    .matches(/^\d+$/, "Contact must contain only numbers"),
  genres: yup
    .array()
    .min(1, "At least one genre is required")
    .required("Genres are required"),
});

const editProfileSchema = yup.object({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required."),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: yup
    .string()
    .matches(/^\d+$/, "Contact must contain only numbers")
    .required("Contact no. is required."),
});

const changePasswordSchema = yup.object().shape({
  current_password: yup.string().required("Current Password is Required"),
  password: yup.string().required("Password is Required"),
  password_confirmation: yup
    .string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
});

const getEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

const MOUSchema = yup.object({
  description: yup.string().required("Description is required"),
  freeBooksLimit: yup
    .number()
    .typeError("Free Books Limit must be a number")
    .required("Free Books Limit is required"),
  premiumBooksLimit: yup
    .number()
    .typeError("Premium Books Limit must be a number")
    .required("Premium Books Limit is required"),
  platformFee: yup
    .number()
    .typeError("Platform Fee must be a number")
    .required("Platform Fee is required"),
});

const mouSignatureSchema = yup.object({
  description: yup.string().required("Description is required"),
  freeBooksLimit: yup
    .number()
    .typeError("Free Books Limit must be a number")
    .required("Free Books Limit is required"),
  premiumBooksLimit: yup
    .number()
    .typeError("Premium Books Limit must be a number")
    .required("Premium Books Limit is required"),
  platformFee: yup
    .number()
    .typeError("Platform Fee must be a number")
    .required("Platform Fee is required"),
  signature: yup.string().required("Signature is required"),
});

const dateSchema = yup.object({
  startDate: yup.string().required("Start date is required"),
  endDate: yup.string().required("End date is required"),
  croppedImg: yup.string().required("Cropped Image is required"),
});

const uploadReceiptSchema = yup.object({
  receipt_image: yup.string().required("Receipt image is required"),
  payment_date: yup.string().required("Payment date is required"),
  transaction_id: yup.string().required("Transaction id is required"),
});

const customNotificationValidation = yup.object({
  role: yup
    .array()
    .min(1, "Select at least one role to continue.")
    .required("Role is required."),
  method: yup
    .array()
    .min(1, "Select at least one type to continue.")
    .required("Notification type is required"),
  direction: yup.mixed().nullable().optional(),
  selectedItem: yup.object().required("Select item to continue"),
  title: yup.string().required("Title is required"),
  body: yup.string().required("Description is required"),
});

const announcementBannerValidation = yup.object({
  banner_image: yup.string().required("Banner image is required"),
  title: yup.string().required("Title is required"),
  user_type: yup.string().required("User type is required"),
  is_clickable: yup.string().optional(),
  button_image: yup.string().optional(),
});

export {
  loginSchema,
  createTicketSchema,
  createBookReviewerSchema,
  editProfileSchema,
  changePasswordSchema,
  getEmailSchema,
  MOUSchema,
  dateSchema,
  mouSignatureSchema,
  uploadReceiptSchema,
  customNotificationValidation,
  announcementBannerValidation,
};

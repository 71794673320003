// const BaseURL = "https://api.aliflaila.app/api/admin/";
// const PublicURL = "https://api.aliflaila.app/api/";

const PublicURL = "https://api-staging.aliflaila.app/api/";
const BaseURL = "https://api-staging.aliflaila.app/api/admin/";

const Endpoint = {
  auth: {
    login: "login",
    logout: "logout",
    sendOTP: "send-email-otp",
    verifyOTP: "verify-otp",
    resetPassword: "reset-password",
  },
  profile: {
    getProfile: "profile",
    updateProfile: "profile/update",
    changePassword: "profile/change-password",
    notificationSettings: "profile/notification-settings",
  },
  dashboard: {
    adminDashboard: "stats/adminuser/dashboard",
  },
  admin: {
    getUsers: "adminuser/users/partners",
    bookReviewers: "stats/adminuser/reviewers",
    getAllBookReviewers: "adminuser/reviewers/list",
    getBookReviewerDetails: "adminuser/reviewers/profile/",
    getBookReviewerBooks: "adminuser/reviewers/",
    getAllUsers: "adminuser/tickets/users/drop-list",
    createTicket: "adminuser/tickets/create",
    createTicketAndComment: "adminuser/tickets/create-and-comment",
    addComment: "adminuser/tickets/comment",
    allTickets: "/adminuser/tickets/users/list",
    authorStats: "/stats/adminuser/authors",
    publisherStats: "/stats/adminuser/publishers",
    createBookReviewer: "/adminuser/reviewers/create",
    getTickets: "/adminuser/tickets/list/",
    getConversation: "adminuser/tickets/thread/",
    closeTicket: "adminuser/tickets/close/",
    getAuthorDetails: "adminuser/users/author/",
    getMOUDetail: "adminuser/mou/view/",
    getAllMOUs: "adminuser/mou/list/",
    assignToHead: "/adminuser/mou/assign-to-head/",
    deleteMOU: "adminuser/mou/delete/",
    updateMOU: "adminuser/mou/update",
    createMOU: "adminuser/mou/create",
    getPublisherProfile: "adminuser/users/publisher/",
    blockReviewer: "adminuser/reviewers/block/",
    unblockReviewer: "adminuser/reviewers/unblock/",
    getAllSchoolAccounts: "/adminuser/schools/list",
    changeLimit: "/adminuser/schools/change-limit",
    approveSchool: "/adminuser/schools/approve",
    rejectSchool: "/adminuser/schools/reject",
    schoolStats: "/stats/adminuser/schools",
    blockSchool: "/adminuser/schools/block",
    unBlockSchool: "adminuser/schools/unblock",
  },
  hod: {
    getStats: "stats/hodcontent/dashboard",
    getBooks: "hodcontent/books",
    bookReviewers: "stats/hodcontent/reviewers",
    getAllBookReviewers: "hodcontent/reviewers/list",
    getAllBooks: "hodcontent/books/",
    changeStatusBook: "hodcontent/books/change-status",
    assignReviewer: "hodcontent/books/review/assign-to-reviewer",
    approveBooks: "hodcontent/books/approve",
    rejectBook: "hodcontent/books/reject",
    publishBook: "hodcontent/books/publish",
    sendBackRevision: "hodcontent/books/review/send-revision",
    approvalPendingBooks: "hodcontent/books/review/approval-pending",
    recentRevision: "hodcontent/books/review/recent-revision/",
    getAllBookReviewersBooks: "hodcontent/reviewers/",
    getBookHistory: "hodcontent/books/review/history/",
    getReviewerProfile: "/hodcontent/reviewers/profile/",
  },
  general: {
    getAllLanguages: "languages/list",
    getKeywords: "tags/list",
    getAllAgeRanges: "age-ranges/list",
    getGenres: "genres/list",
    getBookDetail: "admin/books/details/",
    getBookPages: "books/details/",
  },
  book_reviewer: {
    getBooks: "bookreviewer/books/list",
    getStats: "stats/bookreviewer/dashboard",
    assignBackToHOD: "bookreviewer/books/review/submit-review",
    recentHistory: "bookreviewer/books/review/history/",
    recentRevision: "bookreviewer/books/review/recent-revision/",
  },
  product_manager: {
    getAllPlans: "productmanager/plans/list",
    getAllSchools: "productmanager/plans/schools-list",
    createPlan: "productmanager/plans/create",
    updatePlan: "productmanager/plans/update",
    deletePlan: "productmanager/plans/delete/",
    activatePlan: "productmanager/plans/activate/",
    inactivatePlan: "productmanager/plans/inactivate/",
    getAllBalance: "productmanager/topup-balance/list",
    addBalance: "productmanager/topup-balance/add",
    updateBalance: "productmanager/topup-balance/update",
    deleteBalance: "productmanager/topup-balance/delete/",
    allFeaturedBooks: "productmanager/featured-books/list",
    approveFeatureBook: "productmanager/featured-books/approve/",
    rejectFeatureBook: "productmanager/featured-books/reject",
    uploadFeatureCover: "productmanager/featured-books/upload-cover",
    getAllPublishers: "productmanager/featured-books/publishers",
    getPublisherBooks: "productmanager/featured-books/publishers/",
    addBook: "productmanager/featured-books/add",
    activateBook: "productmanager/featured-books/activate/",
    deactivateBook: "productmanager/featured-books/deactivate/",
    bookHistory: "productmanager/featured-books/history/",
    reportedBooksLists: "productmanager/reported-books/list",
    pendingBooksLists: "productmanager/reported-books/pending-reports/",
    historyBookLists: "productmanager/reported-books/history/",
    approveBook: "productmanager/reported-books/approve/",
    declineBook: "productmanager/reported-books/decline/",
    allGenres: "productmanager/genres/list",
    updateGenres: "productmanager/genres/update",
    deleteGenres: "productmanager/genres/delete/",
    createGenre: "productmanager/genres/create",
    activateGenre: "productmanager/genres/activate/",
    inactiveGenre: "productmanager/genres/inactivate/",
    ticketUsers: "productmanager/tickets/users/list",
    createTicket: "productmanager/tickets/create",
    createTicketAndCommentByProductManager:
      "productmanager/tickets/create-and-comment",
    addComment: "productmanager/tickets/comment",
    closeTicket: "productmanager/tickets/close/",
    userTickets: "productmanager/tickets/list/",
    getAllComments: "productmanager/tickets/thread/",
    getAllUserList: "productmanager/tickets/users/drop-list",
    catalogStats: "stats/productmanager/catalogs",
    genreStats: "stats/productmanager/popular-genres",
    booksStats: "stats/productmanager/popular-books",
    softCopySales: "stats/productmanager/graph/soft-copy-sales",
    hardCopySales: "stats/productmanager/graph/hard-copy-sales",
    topSalesSoftCopy: "productmanager/books/top-sales/softcopies",
    topSalesHardCopy: "productmanager/books/top-sales/hardcopies",
    deleteBook: "productmanager/books/delete/",
    revisionHistory: "productmanager/reported-books/revision/history/",
    allGraphs: "stats/productmanager/graph/book-sales",
    salesBooks: "productmanager/books/best-sellers",
    transactionRequests: "productmanager/user-subscriptions/transactions/list",
    subscriptionRequests:
      "/productmanager/user-subscriptions/subscriptions/list",
    userTransactionsList:
      "/productmanager/user-subscriptions/transactions/list",
    rejectTransactionRequest:
      "productmanager/user-subscriptions/transactions/reject",
    approveTransactionRequest:
      "productmanager/user-subscriptions/transactions/approve",
    getPolicyDataAPI: "productmanager/pages/content",
    updateGetPolicyDataAPI: "productmanager/pages/update",
    getNewsletterListProductManager: "/productmanager/newsletters/list",
    deleteNewsletterProductManager: "/productmanager/newsletters/delete/",
    transactionHistoryList:
      "/productmanager/user-subscriptions/payment-transactions/list",
    transactionInquiry:
      "/productmanager/user-subscriptions/payment-transactions/:id/inquiry",
    bannerListings: "/productmanager/banners/list",
    deleteBanner: "/productmanager/banners/delete/:id",
    addBanner: "/productmanager/banners/add",
    individualAccounts: "/productmanager/users/individuals",
    parentsAccounts: "/productmanager/users/parents",
    blockUser: "/productmanager/users/block/:id",
    unblockUser: "/productmanager/users/unblock/:id",
    statsParents: "/stats/productmanager/users/parents",
    statsIndividual: "/stats/productmanager/users/individuals ",
    getAllPublishersList: "productmanager/users/active-publishers-droplist",
    getAllAuthorsList: "productmanager/users/active-authors-droplist",
    getAllBooksList: "productmanager/books/published-books-droplist",
    getAllCustomNotifications: "productmanager/notifications/list-campaigns",
    getCustomNotification: "productmanager/notifications/campaign/:id",
    cancelNotification: "productmanager/notifications/cancel-campaign/:id",
    createNotification: "productmanager/notifications/send",
    publisherProfile: "productmanager/users/publisher/",
    authorProfile: "productmanager/users/author/",
    getAllBanners: "productmanager/announcements/list",
    createPhotoLink: "productmanager/announcements/image-s3bucket-link",
    addAnnouncementBanners: "productmanager/announcements/add",
    updateAnnouncementStatus: "productmanager/announcements/update-status",
    deleteAnnouncementStatus: "productmanager/announcements/delete/:id",
    getSingleAnnouncement: "productmanager/announcements/show/:id",
    updateAnnouncements: "productmanager/announcements/update/:id",
  },
  project_head: {
    allReportedUsers: "projecthead/reported-users/list",
    pendingReports: "projecthead/reported-users/pending-reports/",
    reportHistory: "projecthead/reported-users/history/",
    approveReport: "projecthead/reported-users/approve/",
    declineReport: "projecthead/reported-users/decline/",
    deleteReports: "projecthead/reported-users/delete/",
    publisherProfile: "projecthead/users/publisher/",
    authorProfile: "projecthead/users/author/",
    allMOUs: "projecthead/mou/list/",
    singleMOU: "projecthead/mou/view/",
    deleteMOU: "projecthead/mou/delete/",
    signAgreement: "projecthead/mou/sign-agreement",
    getAllUsers: "projecthead/users/partners",
    getStats: "stats/projecthead/partners",
    getReviewerStats: "stats/projecthead/reviewers",
    getReviewersList: "projecthead/reviewers/list",
    getReviewerProfile: "projecthead/reviewers/profile/",
    getReviewerBooks: "projecthead/reviewers/",
    getBestSellers: "projecthead/books/best-sellers",
    stats: "stats/projecthead/dashboard",
    userGraph: "stats/projecthead/graph/users",
    coinsGraph: "stats/projecthead/graph/coins-earnings",
    softCopyGraph: "stats/projecthead/graph/soft-copy-sales",
    membershipGraph: "stats/projecthead/graph/memberships",
    reviewerStats: "projecthead/reviewers/profile/",
    blockUser: "projecthead/users/block/",
    unblockUser: "projecthead/users/unblock/",
    transactionsList: "projecthead/transactions/list",
    getTransactionStats: "stats/projecthead/payments",
    rejectMOU: "projecthead/mou/reject/",
    getNewsletterProjectHead: "/projecthead/newsletters/list",
    deleteNewsletterProjectHead: "/projecthead/newsletters/delete/",
    subsciptionRequests: "/projecthead/user-subscriptions/subscriptions/list",
    getUserTransactionListForProjectHead:
      "/projecthead/user-subscriptions/transactions/list",
    blockPartnerUser: "/projecthead/users/block",
    unBlockPartnerUser: "/projecthead/users/unblock",
    getAllPublishers: "projecthead/users/active-publishers-droplist",
    getAllAuthors: "projecthead/users/active-authors-droplist",
    getAllBooks: "projecthead/books/published-books-droplist",
    getAllCustomNotifications: "projecthead/notifications/list-campaigns",
    getCustomNotification: "projecthead/notifications/campaign/:id",
    cancelNotification: "projecthead/notifications/cancel-campaign/:id",
    createNotification: "projecthead/notifications/send",
  },
  accountant: {
    getEarnings: "accountant/earnings/list",
    uploadReceipt: "accountant/earnings/upload-receipt",
    getBankAccounts: "accountant/user/accounts",
    getStats: "stats/accountant/payments",
  },
};

export { BaseURL, Endpoint, PublicURL };

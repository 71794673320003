import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DetailsCard from "shared/components/DetailsCard";
import TableSkeleton from "shared/components/TableSkeleton";
import ItemsPerPage from "shared/components/itemsPerPage";
import NoDataFound from "shared/components/noDataFound";
import Pagination from "shared/components/pagination";
import { toastMessage } from "shared/components/toast";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import useDebounce from "shared/customHooks/useDebounce";
import useWindowSize from "shared/customHooks/windowSize";
import { plan_level_enums } from "shared/modals/managePlans/constant";
import { routeConstant } from "shared/routes/routesConstant";
import Loader from "shared/components/loader/animation";
import {
  approveTransactionByProductManager,
  getTransactionRequestList,
  rejectTrasactionByProductManager,
} from "shared/services/productManagerService";
import { searchHandlerHelper } from "shared/utils/helpers";
import {
  TRANSACTION_STATUSES,
  bankCredentials,
  // SUBSCRIPTION_STATUS,
  bankDetails,
  columnsHeadings,
  headings,
  skeletonInfo,
  transactionTypes,
} from "./constants";
import styles from "./style.module.scss";

const SubscriptionRequest = () => {
  const screenWidth = useWindowSize();
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [openViewReceiptModal, setViewOpenReceipt] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);
  const [requestList, setRequestList] = useState<any>([]);
  const [totalPage, setTotalPage] = useState<number>(10);
  const [viewModalImage, setViewModalImage] = useState<string>("");
  const [itemId, setItemId] = useState<number | null>(null);
  const [rejectMessage, setRejectMessage] = useState<string>("");
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [showReasonModal, setShowReasonModal] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState("");
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [confirmLoader, setConfirmLoader] = useState<boolean>(false);

  const handleView = (item: any) => {
    setViewOpenReceipt(true);
    setViewModalImage(item?.receipt_image);
  };

  const handleReject = (item: any) => {
    setOpenRejectModal(true);
    setItemId(item?.id);
  };

  const handleApprove = (item: any) => {
    setOpenApproveModal(true);
    setItemId(item?.id);
  };

  const handleCloseModal = () => {
    setViewOpenReceipt(false);
    setOpenRejectModal(false);
    setRejectMessage("");
  };

  const handleViewRejectReason = (item: any) => {
    setShowReasonModal(true);
    setRejectReason(item?.reason);
  };

  const handleConfirmApprove = () => {
    setConfirmLoader(true);
    if (itemId) {
      approveTransactionByProductManager(itemId)
        .then((data) => {
          // const updatedRequests = requestList.filter((request: any) => {
          //   return request.id !== itemId;
          // });
          if (data?.data?.status === false) {
            setOpenApproveModal(false);
            toastMessage("error", data?.data?.message);
            getTransactionsList();
          } else {
            toastMessage("success", data?.data?.message);
            setOpenApproveModal(false);
            getTransactionsList();
            // setRequestList(updatedRequests);
          }

          setConfirmLoader(false);
        })
        .catch((err) => {
          console.log("Error--", err);
          toastMessage("error", err?.response?.data?.message);
          setConfirmLoader(false);
        });
    }
  };

  const handleSave = () => {
    rejectTrasactionByProductManager(Number(itemId), rejectMessage)
      .then((data) => {
        // const updatedRequests = requestList.filter((request: any) => {
        //   return request.id !== itemId;
        // });
        if (data?.data?.status === false) {
          toastMessage("error", data?.data?.message);
          setOpenRejectModal(false);
          setRejectMessage("");
          getTransactionsList();
        } else {
          toastMessage("success", data?.data?.message);
          // setRequestList(updatedRequests);
          setOpenRejectModal(false);
          setRejectMessage("");
          getTransactionsList();
        }
      })
      .catch((err) => {
        console.log("Error--", err);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const getTransactionsList = () => {
    setLoader(true);
    getTransactionRequestList(search, activePage, itemsPerPage)
      .then(({ data, status }) => {
        if (status) {
          setRequestList(data?.data?.data);
          setLoader(false);
          setTotalPage(data?.data?.last_page);
          setTotalItems(data?.data?.total);
          setItemsPerPage(data?.data?.per_page);
          setCurrentPage(data?.data?.current_page);
        }
      })
      .catch((err: any) => {
        console.log("error---", err);
        setLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getTransactionsList();
    //eslint-disable-next-line
  }, [searchText, activePage, itemsPerPage]);

  useBreadcrumbs({
    title: "Subscription Requests",
    path: routeConstant.subscriptionRequest.path,
  });

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );
  return (
    <section>
      <DetailsCard
        account={{ title: "Bank Name", value: "United Bank Limited" }}
        info={bankCredentials}
        loader={loader}
        details={bankDetails}
      ></DetailsCard>

      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>{headings.mainHeading}</div>
            <div className={styles.tableSubHeading}>{headings.subHeading}</div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          className={styles.container}
          component={Paper}
          sx={
            screenWidth > 990
              ? {
                  width: `${screenWidth - 300}px`,
                }
              : {
                  boxShadow: "none",
                }
          }
        >
          <Table className={styles.table} aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                {columnsHeadings?.map((heading, index) => {
                  return <TableCell key={index}>{heading.title}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <TableSkeleton
                  rows={skeletonInfo.rows}
                  cellCount={skeletonInfo.cellCount}
                  actionCount={skeletonInfo.actions}
                  showPersonData={false}
                ></TableSkeleton>
              ) : (
                requestList?.map((item: any, index: number) => {
                  const {
                    transaction_id,
                    user,
                    metadata: { name: planName },
                    price,
                    coins,
                    transaction_date,
                    transaction_type: transType,
                    status,
                  } = item;

                  const transactionType = transactionTypes?.find((type) => {
                    return type.id === transType;
                  });
                  const transaction_status =
                    status === TRANSACTION_STATUSES.pending.value
                      ? "Pending"
                      : status === TRANSACTION_STATUSES.approved.value
                      ? "Approved"
                      : status === TRANSACTION_STATUSES.rejected.value
                      ? "Rejected"
                      : status === TRANSACTION_STATUSES.failed.value
                      ? "Failed"
                      : null;
                  const userFullName = user?.parent
                    ? user?.parent?.full_name
                    : user?.reader
                    ? user?.reader?.full_name
                    : user?.school
                    ? user?.school?.full_name
                    : "";

                  return (
                    <>
                      <TableRow key={index} className={styles.tableRow}>
                        <TableCell>
                          {transaction_id ? transaction_id : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {userFullName ? userFullName : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {planName ? planName : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {user?.email ? user?.email : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {item?.plan?.level ===
                            plan_level_enums.in_app_international ||
                          item?.plan?.level === plan_level_enums.international
                            ? "$"
                            : "Rs."}
                          {price ? parseInt(price) : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {item?.plan?.level ===
                          plan_level_enums.in_app_international
                            ? "In App International"
                            : item?.plan?.level === plan_level_enums.inapp
                            ? "In App"
                            : item?.plan?.level === plan_level_enums.genera
                            ? "General"
                            : item?.plan?.level ===
                              plan_level_enums.international
                            ? "International"
                            : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {coins ? parseInt(coins) : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {transactionType
                            ? transactionType.name
                            : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {transaction_date
                            ? moment(transaction_date).format("DD MMM YYYY")
                            : "Not Provided"}
                        </TableCell>
                        <TableCell className={styles.statusCell}>
                          <label
                            className={
                              transaction_status === "Rejected"
                                ? styles.rejected
                                : transaction_status === "Pending"
                                ? styles.pending
                                : ""
                            }
                          >
                            {transaction_status
                              ? transaction_status
                              : "Not Provided"}
                          </label>
                        </TableCell>
                        {/* <TableCell>
                          <Box>
                            <span>
                              <Tooltip title="View">
                                <button
                                  onClick={() => handleView(item)}
                                  className={styles.btnStyles}
                                >
                                  <RemoveRedEye />
                                </button>
                              </Tooltip>
                            </span>
                            {transaction_status !== "Rejected" && (
                              <>
                                <span>
                                  <Tooltip title="Approve">
                                    <button
                                      onClick={() => handleApprove(item)}
                                      className={styles.btnStyles}
                                    >
                                      <Check />
                                    </button>
                                  </Tooltip>
                                </span>
                                <span>
                                  <Tooltip title="Reject">
                                    <button
                                      onClick={() => handleReject(item)}
                                      className={styles.btnStyles}
                                    >
                                      <Close />
                                    </button>
                                  </Tooltip>
                                </span>
                              </>
                            )}
                            {transaction_status === "Rejected" && (
                              <span>
                                <Tooltip title="Reason of Rejection">
                                  <button
                                    onClick={() => handleViewRejectReason(item)}
                                    className={styles.btnStyles}
                                  >
                                    <ErrorOutlineIcon />
                                  </button>
                                </Tooltip>
                              </span>
                            )}
                          </Box>
                        </TableCell> */}
                        <TableCell>
                          <div className={styles.actionContainer}>
                            <Tooltip
                              componentsProps={{
                                tooltip: { className: "tooltip" },
                                arrow: { className: "arrow" },
                              }}
                              placement="top"
                              arrow
                              title="View Receipt"
                            >
                              <div onClick={() => handleView(item)}>
                                <Icons.EyeIcon2 />
                              </div>
                            </Tooltip>
                            {transaction_status !== "Rejected" ? (
                              <>
                                {" "}
                                <Tooltip
                                  componentsProps={{
                                    tooltip: { className: "tooltip" },
                                    arrow: { className: "arrow" },
                                  }}
                                  placement="top"
                                  arrow
                                  title="Approve"
                                >
                                  <div onClick={() => handleApprove(item)}>
                                    <Icons.CrossTickIcon />
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  componentsProps={{
                                    tooltip: { className: "tooltip" },
                                    arrow: { className: "arrow" },
                                  }}
                                  placement="top"
                                  arrow
                                  title="Reject"
                                >
                                  <div onClick={() => handleReject(item)}>
                                    <Icons.CircleCrossIcon />
                                  </div>
                                </Tooltip>
                              </>
                            ) : (
                              <Tooltip
                                componentsProps={{
                                  tooltip: { className: "tooltip" },
                                  arrow: { className: "arrow" },
                                }}
                                placement="top"
                                arrow
                                title="Rejection Reason"
                              >
                                <div
                                  onClick={() => handleViewRejectReason(item)}
                                >
                                  <ErrorOutlineIcon
                                    sx={{ fill: "rgb(134, 142, 150)" }}
                                  />
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          {totalItems &&
          activePage &&
          totalPage &&
          totalPage > 1 &&
          requestList.length ? (
            <Pagination
              totalPages={totalPage}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          ) : null}
        </Box>
        <Box>
          {totalItems && activePage && currentPage && requestList.length ? (
            <ItemsPerPage
              setActivePage={setActivePage}
              currentPage={currentPage}
              setItemsPerPage={setItemsPerPage}
              itemsPerPage={itemsPerPage ? itemsPerPage : 10}
              totalItems={totalItems}
            />
          ) : null}
        </Box>
        <Box>{!loader && !(requestList?.length > 0) && <NoDataFound />}</Box>
      </Box>

      {openViewReceiptModal && (
        <Modal
          modalHeading="Receipt View"
          viewOnly={true}
          showButtons={true}
          state={openViewReceiptModal}
          handleClose={handleCloseModal}
          receiptImage={viewModalImage}
          showReceipt={true}
        ></Modal>
      )}
      {openRejectModal && (
        <Modal
          state={openRejectModal}
          modalHeading="Add Reason for Rejection"
          modalText="* Reason will be send to the User"
          viewOnly={false}
          showButtons={true}
          handleClose={handleCloseModal}
          handleConfirm={handleSave}
          showTextArea
          textAreaMsg={rejectMessage}
          textAreaHandler={(e: any) => setRejectMessage(e.target.value)}
        ></Modal>
      )}
      {openApproveModal && (
        <Modal
          modalHeading="Are you sure you want to approve the request?"
          state={openApproveModal}
          handleClose={() => setOpenApproveModal(false)}
          showButtons={true}
          handleConfirm={handleConfirmApprove}
          viewOnly={false}
        ></Modal>
      )}
      {showReasonModal && (
        <Modal
          modalHeading="Reason Of Rejection"
          state={showReasonModal}
          handleClose={() => setShowReasonModal(false)}
          showButtons={true}
          viewOnly={false}
          modalText={rejectReason}
          reject={true}
        ></Modal>
      )}

      {confirmLoader && <Loader />}
    </section>
  );
};

interface ModalProps {
  modalHeading: string;
  modalText?: string;
  viewOnly?: boolean;
  showTextArea?: boolean;
  showButtons?: boolean;
  state: boolean;
  handleClose: any;
  handleSave?: () => {};
  receiptImage?: string;
  handleConfirm?: any;
  textAreaMsg?: string;
  textAreaHandler?: any;
  reject?: boolean;
  showReceipt?: boolean;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Modal = ({
  modalHeading,
  state,
  viewOnly,
  showButtons,
  handleClose,
  receiptImage,
  showTextArea,
  modalText,
  textAreaMsg,
  textAreaHandler,
  handleConfirm,
  reject,
  showReceipt,
}: ModalProps) => {
  return (
    <Dialog
      open={state}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <Icons.CrossIcon
        className={styles.crossIcon}
        onClick={() => handleClose()}
      />
      <Box padding={"20px"} position={"relative"}>
        {/* <Icons.CrossIcon
          className={styles.crossIcon}
          onClick={() => handleClose()}
        /> */}
        <Box>
          {reject && (
            <Icons.RejectionIcon
              style={{ height: "60px", width: "60px", marginRight: "15px" }}
            />
          )}
          <DialogTitle id="alert-dialog-slide-title" padding={"0px !important"}>
            {modalHeading}
          </DialogTitle>
        </Box>
        <DialogContent sx={{ padding: "0px" }}>
          {!viewOnly && modalText && (
            <DialogContentText id="alert-dialog-slide-description">
              {reject && (
                <textarea
                  value={modalText}
                  className={styles.textAreaReject}
                  disabled
                />
              )}
            </DialogContentText>
          )}
          {showTextArea && (
            <>
              <textarea
                autoFocus
                value={textAreaMsg}
                onChange={textAreaHandler}
                className={styles.textarea}
              />
              <div className={styles.note}>{modalText}</div>
            </>
          )}
          {viewOnly && receiptImage && (
            <div className={styles.receiptImg}>
              <img
                src={receiptImage}
                alt="Receipt"
                className={styles.receiptImg}
              />
            </div>
          )}
        </DialogContent>
        {showButtons && (
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "0px !important",
              marginTop: "20px",
            }}
          >
            {!reject && !showReceipt && (
              <Button
                className={styles.saveBtn}
                onClick={handleConfirm}
                color="primary"
              >
                Save
              </Button>
            )}
            <Button onClick={handleClose} className={styles.cancelBtn}>
              {reject || showReceipt ? "Close" : "Cancel"}
            </Button>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
};

export default SubscriptionRequest;

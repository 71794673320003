import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Icons } from "assets";
import classNames from "classnames";
import useWindowSize from "shared/customHooks/windowSize";
import { planCategories } from "../constant";
import SkeletonLoader from "../skeletonLoader";
import styles from "./../style.module.scss";
import { plan_level_enums } from "shared/modals/managePlans/constant";

function PlansTable({
  activeTab,
  setIsSchoolTabSelected,
  handleOpenForm,
  dataLoader,
  allPlans,
  setViewOnly,
  setEditOnly,
  handleOpenConfirmation,
  setOpenAdd,
  setSelectedItem,
  setSelectedPlan,
}: any) {
  const screenWidth = useWindowSize();

  return (
    <Box className={styles.topLevelContainer}>
      <TableContainer
        component={Paper}
        className={styles.container}
        sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
      >
        <Table className={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableHead}>
              {activeTab === 3 ? <TableCell>School Name</TableCell> : null}
              <TableCell>Plan Title</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Coins</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLoader ? (
              <SkeletonLoader activeTab={activeTab} />
            ) : (
              allPlans
                ?.filter((item: any) => item?.category === activeTab)
                .map((item: any) => {
                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        {activeTab === 3 ? (
                          <TableCell>
                            {item?.school_name ? item?.school_name : "--"}
                          </TableCell>
                        ) : null}
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "7px",
                            }}
                          >
                            {item?.name ? item?.name : "--"}
                            {activeTab !== 3 && item?.in_app ? (
                              <Icons.InAppIcon />
                            ) : null}
                          </div>
                        </TableCell>
                        <TableCell>
                          {item?.level ===
                            plan_level_enums.in_app_international ||
                          item?.level === plan_level_enums.international
                            ? "$"
                            : "Rs."}
                          {item?.price}{" "}
                        </TableCell>
                        <TableCell>{item?.coins}</TableCell>
                        <TableCell>{item?.duration + " Months"}</TableCell>

                        <TableCell>
                          {item?.is_discounted ? "Available" : "Not Available"}
                        </TableCell>

                        <TableCell>
                          <div className={styles.actionContainer}>
                            <Tooltip
                              componentsProps={{
                                tooltip: { className: "tooltip" },
                                arrow: { className: "arrow" },
                              }}
                              placement="top"
                              arrow
                              title="View"
                            >
                              <div
                                onClick={() => {
                                  handleOpenForm(item);
                                  setViewOnly(true);
                                  setEditOnly(false);
                                }}
                              >
                                <Icons.EyeIcon2 />
                              </div>
                            </Tooltip>
                            <Tooltip
                              componentsProps={{
                                tooltip: { className: "tooltip" },
                                arrow: { className: "arrow" },
                              }}
                              placement="top"
                              arrow
                              title="Edit"
                            >
                              <div
                                onClick={() => {
                                  if (activeTab === planCategories.school) {
                                    setIsSchoolTabSelected(true);
                                  } else {
                                    setIsSchoolTabSelected(false);
                                  }
                                  // handleOpenForm(item);
                                  setEditOnly(true);
                                  setOpenAdd(true);
                                  setSelectedItem(item);
                                  // setSelectedPlan(item);
                                }}
                              >
                                <Icons.CreateActionIcon />
                              </div>
                            </Tooltip>
                            <Tooltip
                              componentsProps={{
                                tooltip: { className: "tooltip" },
                                arrow: { className: "arrow" },
                              }}
                              placement="top"
                              arrow
                              title={`${
                                item?.status ? "Dectivate" : "Activate"
                              }`}
                            >
                              <div
                                onClick={() =>
                                  handleOpenConfirmation(
                                    `Are you sure you want to ${
                                      item?.status === 1
                                        ? "inactivate"
                                        : "activate"
                                    } this plan?`,
                                    `${
                                      item?.status === 0
                                        ? "active"
                                        : item?.status === 1
                                        ? "inactive"
                                        : ""
                                    }`,
                                    item
                                  )
                                }
                              >
                                <Icons.LightningIcon
                                  className={
                                    item?.status === 0
                                      ? styles.inactiveIcon
                                      : styles?.activeIcon
                                  }
                                />
                              </div>
                            </Tooltip>
                            <Tooltip
                              componentsProps={{
                                tooltip: { className: "tooltip" },
                                arrow: { className: "arrow" },
                              }}
                              placement="top"
                              arrow
                              title="Delete"
                            >
                              <div
                                onClick={() =>
                                  handleOpenConfirmation(
                                    "Are you sure, you want to delete this plan?",
                                    "delete",
                                    item
                                  )
                                }
                              >
                                <Icons.DeleteIcon />
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        {allPlans?.filter(
          (plan: any) => plan.category === planCategories.school
        ).length === 0 && activeTab === planCategories.school ? (
          <NoDataCard />
        ) : allPlans?.filter(
            (plan: any) => plan.category === planCategories.individual
          ).length === 0 && activeTab === planCategories.individual ? (
          <NoDataCard />
        ) : allPlans?.filter(
            (plan: any) => plan.category === planCategories.family
          ).length === 0 && activeTab === planCategories.family ? (
          <NoDataCard />
        ) : null}
      </Box>
    </Box>
  );
}

const NoDataCard = () => {
  return (
    <div className={classNames(styles.noDataCard, styles.noData)}>
      <Icons.NoDataIcon />
      <h5>No Data Found</h5>
    </div>
  );
};

export default PlansTable;

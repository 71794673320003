import CloseIcon from "@mui/icons-material/Close";
import { Box, Checkbox, Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Icons } from "assets";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import Loader from "shared/components/loader/circular";
import {
  createNotification,
  getAllAuthors,
  getAllBooks,
  getAllPublishers,
} from "shared/services/projectHeadService";
import {
  createNotification as createNotificationManager,
  getAllAuthors as getAllAuthorsManager,
  getAllBooks as getAllBooksManager,
  getAllPublishers as getAllPublishersManager,
} from "shared/services/productManagerService";
import { customNotificationValidation } from "shared/utils/validationSchemas";
import {
  directions,
  directionsEnums,
  roles,
  types,
  typesEnums,
  user_roles,
} from "./constant";
import styles from "./style.module.scss";
import moment from "moment";
import { toastMessage } from "shared/components/toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { roles as admin_roles } from "shared/utils/constants";

function CustomNotifications() {
  const {
    user: {
      user: { role },
    },
  } = useSelector((state: any) => state.root);
  const navigate = useNavigate();
  const [allRoles, setAllRoles] = useState<any>(roles);
  const [notificationTypes, setNotificationTypes] = useState<any>(types);
  const [search, setSearch] = useState<string>("");
  const [selected, setSelected] = useState<string | null>(null);
  const [selectionOptions, setSelectionOptions] = useState<any>([]);
  const [showSchedule, setShowSchedule] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [notificationDirections, setNotificationDirections] =
    useState<any>(directions);
  const [userNameRoleSelected, setUserNameRoleSelected] = useState<any>(false);
  const [date, setDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [selectedTime, setSelectedTime] = useState<any>("00:00");
  const DateBtn = React.forwardRef(
    ({ value, onClick, className }: any, ref: any) => (
      <button
        className={classNames(styles.scheduleBtn)}
        onClick={onClick}
        ref={ref}
      >
        {date ? moment(date).format("DD MMM, YYYY") : "Select Date"}
      </button>
    )
  );

  const formik: any = useFormik({
    initialValues: {
      role: [],
      method: [],
      direction: null,
      selectedItem: {},
      title: "",
      body: "",
    },
    validationSchema: customNotificationValidation,
    onSubmit: (values: any) => {
      let service =
        Number(role) === admin_roles?.productManager
          ? createNotificationManager
          : createNotification;

      let resp: any = {
        roles: values?.role?.map((item: any) => item?.id),
        notification_methods: values?.method?.map((item: any) => item?.id),
        direction_type: values?.direction?.param
          ? values?.direction?.param
          : null,
        title: values?.title,
        body: values?.body,
      };

      if (Object.keys(values?.selectedItem)?.length) {
        resp["direction_id"] = values?.selectedItem?.id;
      }

      if (showSchedule) {
        resp["schedule_date"] = moment(date).format("YYYY-MM-DD");
        resp["schedule_time"] = moment(selectedTime, "HH:mm").format("hh:mm A");
      }

      setLoader(true);
      service(resp)
        .then(({ data: { message, status } }) => {
          if (status) {
            toastMessage("success", message);
            formik?.resetForm();
            navigate(routeConstant.notificationListings.path);
          } else {
            toastMessage("error", message);
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log("err", err?.response?.data?.message);
          toastMessage("error", err?.response?.data?.message);
          setLoader(false);
        });
    },
  });

  const { handleSubmit, values, setFieldValue } = formik;

  useEffect(() => {
    const hasRoleSelected = allRoles.some(
      (role: any) =>
        role.selected &&
        [
          user_roles.teacher,
          user_roles.family_kid,
          user_roles.student,
        ].includes(role.id)
    );
    setUserNameRoleSelected(hasRoleSelected);
  }, [allRoles, values?.role]);

  const handleSelectRole = (id: number) => {
    const updatedRoles = allRoles.map((role: any) =>
      role.id === id ? { ...role, selected: !role.selected } : role
    );

    setAllRoles(updatedRoles);

    const updatedNotificationTypes = notificationTypes.map((type: any) => {
      return type.id === typesEnums.email ? { ...type, selected: false } : type;
    });
    setFieldValue(
      "method",
      updatedNotificationTypes?.filter((item: any) => item?.selected)
    );
    setNotificationTypes(updatedNotificationTypes);

    const updatedNotificationDirections = notificationDirections.map(
      (direction: any, index: number) => {
        if (
          id !== user_roles.parent &&
          id !== user_roles.individual_reader &&
          values?.direction?.id === directionsEnums?.plans
        ) {
          if (index === 0) {
            setFieldValue("direction", null);
            return { ...direction, selected: false };
          } else if (index === 1) {
            // setFieldValue("direction", { ...direction, selected: true });
            setFieldValue("selectedItem", {});
            // return { ...direction, selected: true };
          }
        }
        return direction;
      }
    );
    setNotificationDirections(updatedNotificationDirections);

    setFieldValue(
      "role",
      updatedRoles.filter((role: any) => role?.selected)
    );
  };

  const handleSelectType = (id: number) => {
    const updatedNotificationTypes = notificationTypes.map((type: any) =>
      type.id === id ? { ...type, selected: !type.selected } : type
    );
    setNotificationTypes(updatedNotificationTypes);

    const selectedTypes = updatedNotificationTypes.filter(
      (type: any) => type.selected
    );
    setFieldValue("method", selectedTypes.length ? selectedTypes : null);

    // if user have already entered more than 140 words and then select push in option, concat string to its limited size.
    if (id === typesEnums?.push && values?.body?.length > 140) {
      setFieldValue("body", values.body.slice(0, 140));
    }
  };

  const handleSelectDirection = (id: number) => {
    const isPrevSelected = notificationDirections?.find(
      (item: any) => item?.id === id
    )?.selected;

    const updatedDirections = notificationDirections.map((direction: any) => ({
      ...direction,
      selected: direction.id === id ? !direction.selected : false,
    }));

    setNotificationDirections(updatedDirections);
    setSearch("");

    const selectedDirection =
      updatedDirections.find((direction: any) => direction.selected) || null;
    setFieldValue("direction", selectedDirection);

    if (id === directionsEnums?.plans) {
      setFieldValue("selectedItem", {});
    } else if (isPrevSelected) {
      setFieldValue("selectedItem", {});
    } else {
      setFieldValue("selectedItem", null);
    }
    setSelected(null);
  };

  const getDropdownList = async () => {
    let service;
    switch (values?.direction?.id) {
      case directionsEnums.books:
        service =
          Number(role) === admin_roles?.productManager
            ? getAllBooksManager
            : getAllBooks;
        break;
      case directionsEnums.authors:
        service =
          Number(role) === admin_roles?.productManager
            ? getAllAuthorsManager
            : getAllAuthors;
        break;
      case directionsEnums.publishers:
        service =
          Number(role) === admin_roles?.productManager
            ? getAllPublishersManager
            : getAllPublishers;
        break;
      default:
        service = null;
    }
    if (service) {
      try {
        const response = await service(search);
        setSelectionOptions(response.data.data.data);
      } catch (error: any) {
        console.error("Error fetching data:", error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    getDropdownList();
    // eslint-disable-next-line
  }, [search, values.direction]);

  const handleRemoveSelected = () => {
    setSelected(null);
    setFieldValue("selectedItem", null);
  };

  useBreadcrumbs(
    {
      title: "Custom Notification",
      path: routeConstant.customNotifications.path,
    },
    true
  );

  useEffect(() => {
    const hasUserNameRole = allRoles.some(
      (role: any) =>
        role.selected &&
        [
          user_roles.teacher,
          user_roles.family_kid,
          user_roles.student,
        ].includes(role.id)
    );

    setUserNameRoleSelected(hasUserNameRole);
  }, [allRoles]);

  const handleTimeChange = (event: any) => {
    setSelectedTime(event.target.value);
  };

  return (
    <Box className={styles.statsContainer}>
      <div className={styles.containerheading}>Select Roles</div>
      <div>
        <Grid container className={styles.mainGrid} spacing={2} zIndex={9}>
          {allRoles?.map((item: any) => {
            let Icon = item?.icon;
            return (
              <Grid item xs={12} md={6} lg={4}>
                <div
                  className={styles.singleStatsCard}
                  onClick={() => handleSelectRole(item?.id)}
                >
                  <div className={styles.label}>{item?.label}</div>
                  <CheckBox item={item} handleCallback={handleSelectRole} />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      right: "15px",
                    }}
                  >
                    <Icon className={styles.iconStyle} />
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <div className={classNames(styles.error)} style={{ marginTop: "10px" }}>
          {formik.touched.role && formik?.errors?.role
            ? formik?.errors?.role.toString()
            : null}
        </div>
      </div>

      <Grid container spacing={5}>
        <Grid item xs={12} lg={6}>
          <div className={styles.sectionContainer}>
            <label className={styles.containerheading}>
              Select Notification Type
            </label>
            <div className={styles.mapContainer}>
              {notificationTypes?.map((item: any, inx: any) => {
                return (
                  <div className={styles.typeContainer}>
                    <CheckBox
                      item={item}
                      handleCallback={() => handleSelectType(item?.id)}
                      disabled={userNameRoleSelected && inx === 0}
                    />
                    <label
                      className={
                        userNameRoleSelected && inx === 0
                          ? classNames(styles.label, styles.disabledOption)
                          : styles.label
                      }
                    >
                      {item?.label}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={classNames(styles.error)}
            style={{ marginTop: "5px" }}
          >
            {formik.touched.method && formik?.errors?.method
              ? formik?.errors?.method.toString()
              : null}
          </div>
        </Grid>

        <Grid item xs={12} lg={6}>
          <div className={styles.sectionContainer}>
            <label className={styles.containerheading}>
              Select Notification Direction
            </label>
            <div className={styles.mapContainer}>
              {notificationDirections?.map((item: any, inx: any) => {
                return (
                  <div className={styles.typeContainer}>
                    <CheckBox
                      item={item}
                      handleCallback={() => handleSelectDirection(item?.id)}
                      disabled={userNameRoleSelected && inx === 0}
                    />
                    <label
                      className={
                        userNameRoleSelected && inx === 0
                          ? classNames(styles.label, styles.disabledOption)
                          : styles.label
                      }
                    >
                      {item?.label}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={classNames(styles.error)}
            style={{ marginTop: "5px" }}
          >
            {formik.touched.direction && formik?.errors?.direction
              ? formik?.errors?.direction.toString()
              : null}
          </div>
        </Grid>
      </Grid>

      <div className={styles.containerheading} style={{ marginTop: "5px" }}>
        Notification Specifics
      </div>

      <div className={styles.inputMainContainer}>
        <div style={{ position: "relative", width: "100%" }}>
          <input
            className={styles.notificationTitle}
            placeholder="Enter Notification Title"
            onChange={(e: any) => {
              setFieldValue("title", e.target.value);
            }}
          />
          <div
            className={classNames(styles.error)}
            style={{ marginTop: "5px" }}
          >
            {formik.touched.title && formik?.errors?.title
              ? formik?.errors?.title.toString()
              : null}
          </div>
        </div>

        <div style={{ position: "relative", width: "100%" }}>
          {values?.direction?.id !== directionsEnums?.plans &&
          values?.direction ? (
            <AutoComplete
              list={selectionOptions}
              setSearchText={setSearch}
              searchText={search}
              selectedItem={selected}
              setSelectedItem={(item: any) => {
                setSelected(item);
                setFieldValue("selectedItem", item);
              }}
              activeDirection={values?.direction}
              handleRemoveSelected={handleRemoveSelected}
            />
          ) : null}
          <div
            className={classNames(styles.error)}
            style={{ marginTop: "5px" }}
          >
            {formik.touched.selectedItem && formik?.errors?.selectedItem
              ? formik?.errors?.selectedItem.toString()
              : null}
          </div>
        </div>
      </div>
      <div>
        <textarea
          className={styles.textarea}
          placeholder="Enter notification message"
          maxLength={
            values?.method?.some((item: any) => item?.id === typesEnums.push)
              ? 140
              : undefined
          }
          onChange={(e: any) => {
            const newValue = e.target.value;
            const isPushNotification = values?.method?.some(
              (item: any) => item?.id === typesEnums.push
            );

            const wordCount = newValue.trim().split(/\s+/).length;

            if (!isPushNotification || wordCount <= 140) {
              setFieldValue("body", newValue);
            }
          }}
          value={values.body}
        />

        <div className={styles.textareaLabelContent}>
          <div
            className={classNames(styles.error)}
            style={{ marginTop: "5px", width: "100%" }}
          >
            {formik.touched.body && formik?.errors?.body
              ? formik?.errors?.body.toString()
              : null}
          </div>

          {values?.method?.some((item: any) => item?.id === typesEnums.push) ? (
            <div className={styles.limitLabel}>
              {values?.body?.length}/140 limit due to Push Notification
            </div>
          ) : null}
        </div>

        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={showSchedule}
              onChange={() => {
                setShowSchedule(!showSchedule);
                setSelectedTime("00:00");
                setDate(new Date(new Date().setDate(new Date().getDate() + 1)));
              }}
              className={showSchedule ? styles.radioCheckBox : ""}
            />
            <span className={styles.label}>
              Want to schedule this notification?
            </span>
          </div>
          {showSchedule && (
            <div className={styles.scheduleContainer}>
              <ReactDatePicker
                selected={date}
                onChange={(date: any) => setDate(date)}
                customInput={<DateBtn />}
                minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
              />
              <input
                type="time"
                value={selectedTime}
                onChange={handleTimeChange}
                style={{ width: "fit-content" }}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={classNames(
          styles.btnContainer,
          "notificationScheduleBtnContainer"
        )}
      >
        <button
          className={styles.sendNotificationBtn}
          onClick={() => handleSubmit()}
        >
          {loader ? (
            <Loader />
          ) : showSchedule ? (
            "Schedule Notification"
          ) : (
            "Send Notification"
          )}
        </button>
      </div>
    </Box>
  );
}

export default CustomNotifications;

const CheckBox = ({ item, handleCallback, disabled }: any) => {
  return (
    <div
      className={classNames(
        item?.selected
          ? styles.checked
          : disabled
          ? classNames(styles.disabledCheckbox, styles.unchecked)
          : styles.unchecked
      )}
      onClick={() => {
        if (!disabled) {
          handleCallback(item?.id);
        }
      }}
    >
      {item?.selected && <Icons.CheckboxIcon />}
    </div>
  );
};

interface AutoCompleteProps {
  list: any;
  setSearchText: (text: string) => void;
  searchText: string;
  selectedItem: any;
  setSelectedItem: (user: null | any) => void;
  activeDirection: any;
  handleRemoveSelected: () => void;
}

function AutoComplete({
  list,
  setSearchText,
  searchText,
  selectedItem,
  setSelectedItem,
  activeDirection,
  handleRemoveSelected,
}: AutoCompleteProps) {
  const [allList, setAllList] = useState<any>(list ? list : []);

  useEffect(() => {
    setAllList(list);
  }, [list]);

  const handleInputChange = (value: any | null) => {
    setSelectedItem(value);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <Stack spacing={2}>
      {selectedItem ? (
        <div
          className={`${styles.displayAlign} ${styles.selectedNameAutoComplete}`}
        >
          <div className={styles.displayAlign}>
            {activeDirection.id !== directionsEnums.books && (
              <img
                src={selectedItem?.profile_picture}
                className={styles.profile_picture_selected}
                alt="thumbnail"
                style={{ marginRight: "10px" }}
              />
            )}
            <p>
              {selectedItem.title
                ? selectedItem.title
                : selectedItem?.full_name
                ? selectedItem?.full_name
                : ""}
            </p>
          </div>

          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleRemoveSelected();
            }}
          />
        </div>
      ) : (
        <Autocomplete
          autoFocus
          defaultValue={selectedItem}
          id="free-solo-demo"
          freeSolo
          onClose={() => {
            setSearchText("");
          }}
          disableClearable
          options={allList}
          getOptionLabel={(option: any) => {
            return option?.title
              ? option?.title
              : option?.full_name
              ? option?.full_name
              : "";
          }}
          onChange={(_, newValue: any) => {
            handleInputChange(newValue);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <img
                  src={
                    activeDirection.id === directionsEnums.books
                      ? option?.thumbnail
                      : option?.profile_picture
                      ? option?.profile_picture
                      : ""
                  }
                  className={
                    activeDirection.id === directionsEnums.books
                      ? styles.bookPic
                      : styles.profile_picture
                  }
                  alt="thumbnail"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                  }}
                >
                  <span className={styles.menuTitle}>
                    {option?.title
                      ? option?.title
                      : option?.full_name
                      ? option?.full_name
                      : "Not Available"}
                  </span>
                  {activeDirection.id !== directionsEnums.books && (
                    <span className={styles.username}>@{option?.username}</span>
                  )}
                </div>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={`Select ${activeDirection?.label}`}
              value={searchText}
              onChange={(e: any) => handleSearch(e)}
              className={styles.input}
            />
          )}
        />
      )}
    </Stack>
  );
}
